.email-list-grid {
  display: grid;
  gap: 2px; /* Adjust the gap as needed */
}

.email-row {
  display: grid;
  grid-template-columns: 1fr 1fr auto auto; /* Two columns: email content and status */
  gap: 2px; /* Adjust the gap as needed */
  padding: 20px;
  border: 1px solid #ccc;
  background-color: black;
  color: white;
}

.email-content {
  display: grid;
  grid-gap: 2px; /* Adjust the gap as needed */
}

.email-subject {
  font-weight: bold;
  overflow-wrap: break-word; /* Break long words */
}

.email-body {
  word-break: break-word; /* Break words at any character */
}
.email-raw {
  word-break: break-word; /* Break words at any character */
}

.email-status {
  padding: 20px;
  border: 1px solid #ccc;
  background-color: black;
  color: white;
  font-style: italic;
}
